<template>
   <div class="cont">
      <!-- 面包屑 -->
      <el-row class="crumbs-box">
         <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>AIOT</el-breadcrumb-item>
            <el-breadcrumb-item>酒店设备</el-breadcrumb-item>
            <el-breadcrumb-item>产品管理</el-breadcrumb-item>
            <el-breadcrumb-item>产品列表</el-breadcrumb-item>
         </el-breadcrumb>
      </el-row>

      <el-row class="content-box">
         <!-- 表头 -->
         <!-- 表格 -->
         <div class="content-flex-box">
            <div :class="['table-left',boxIcon ? '':'left-no']">
               <el-row class="left-table-bg">
                  <el-row class="left-table">
                     <el-table
                        ref="productTable"
                        v-loading="sideLoad"
                        border fit
                        :data="sideData"
                        highlight-current-row
                        @row-click="selectSideList"
                        row-key="product_id"
                        style="width: 100%;"
                        max-height="780"
                        :stripe="true">
                        <el-table-column :label="$t('msg.room_icon')" width="70">
                           <template slot-scope="scope">
                              <img v-if="scope.row.icon" :src="scope.row.icon" width="40" height="40" class="v-align-middle"/>
                              <img v-else src="https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png" width="40" height="40" class="v-align-middle" alt="">
                           </template>
                        </el-table-column>
                        <el-table-column prop="name" :label="$t('msg.categories_name')"></el-table-column>
                     </el-table>
                  </el-row>
               </el-row>
            </div>
            <div class="right-table-bg" :style="boxIcon ? '': 'width: 98%;'">
               <!-- <div v-if="!loading" :class="['box-icon',boxIcon ? 'mymove1':'mymove2']">
                  <i :class="boxIcon ? 'el-icon-d-arrow-left':'el-icon-d-arrow-right'" @click="boxIcon = !boxIcon"></i>
               </div> -->
               <el-row class="right-table">
                  <el-row class="table-box">
                     <el-row class="search-box">
                        <el-row class="search-row">
                           <el-row class="search-item">
                              <label>名称编码：</label>
                              <el-input class="width-220" v-model="search" :placeholder="$t('msg.room_name_code')"  clearable></el-input>
                           </el-row>
                           <el-button class="bg-gradient" type="primary" @click="handleQuery(true)" ><span v-text="$t('msg.search')">搜索</span></el-button>
                           <el-button type="primary" @click="handleQuery(false)"  plain><span v-text="$t('msg.reset')">重置</span></el-button>
                        </el-row>
                        <el-button class="bg-gradient" type="primary" @click="handleAdd"  style="float: right"><span v-text="$t('msg.add_product')">新建产品</span></el-button>
                     </el-row>
                     <el-table
                        v-loading="loading"
                        :data="tableData"
                        highlight-current-row
                        :stripe="true"
                        border fit
                     >
                        <el-table-column prop="number" :label="$t('msg.number')" width="70"></el-table-column>
                        <el-table-column :label="$t('msg.product_image')" width="70">
                           <template slot-scope="scope">
                              <img v-if="scope.row.image" :src="scope.row.image" width="40" height="40" class="suffix-cursor v-align-middle" @click="handleDetails(scope.row)"/>
                              <img v-else src="https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png" width="40" height="40" class="suffix-cursor v-align-middle" @click="handleDetails(scope.row)" alt="">
                           </template>
                        </el-table-column>
                        <el-table-column prop="id" :label="$t('msg.product_name')" min-width="170"></el-table-column>
                        <el-table-column prop="productKey" :label="$t('msg.product_key')" min-width="120"></el-table-column>
                        <el-table-column prop="productCategoryName" :label="$t('msg.category')" min-width="150"></el-table-column>
                        <el-table-column prop="standard" :label="$t('msg.product_format')" min-width="170"></el-table-column>
                        <el-table-column prop="versions" :label="$t('msg.product_number')" width="120"></el-table-column>
                        <el-table-column prop="createTime" :label="$t('msg.add_time')" min-width="160" sortable></el-table-column>
                        <el-table-column :label="$t('msg.operating')" width="140" fixed="right">
                           <template slot-scope="scope">
                              <el-button  type="text" icon="el-icon-edit" @click="handleEdit(scope.row)" >
                                 <el-link type="primary" v-text="$t('msg.edit')">编辑</el-link>
                              </el-button>
                              <el-button  type="text" style="color: #F56C6C;" icon="el-icon-delete" @click="handleDel(scope.row.id)" >
                                 <el-link type="danger" v-text="$t('msg.remove')">删除</el-link>
                              </el-button>
                           </template>
                        </el-table-column>
                  </el-table>
                  </el-row>

               </el-row>
            </div>
         </div>
         <!-- 新建/编辑弹窗 -->
         <el-form label-width="105px">
            <el-dialog :title="$t('msg.add_product')" :visible.sync="isShow" width="610px" :before-close="handleClose">
               <el-row class="m-bottom-15">
                  <el-col :span="8" class="t-right l-height-26"><b class="needful">*</b><span v-text="$t('msg.category_a')">所属分类：</span></el-col>
                  <el-col :span="16" v-if="productCategoryName">
                     <span v-text="productCategoryName"></span>
                  </el-col>
                  <el-col :span="16" v-else>
                     <el-select  v-model="productTypeId" placeholder="请选择">
                        <el-option
                              v-for="item in productSelect"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value">
                        </el-option>
                     </el-select>
                  </el-col>
               </el-row>
               <el-row class="m-bottom-15">
                  <el-col :span="8" class="t-right l-height-26"><span v-text="$t('msg.product_image_a')">产品图片：</span></el-col>
                  <el-col :span="16" class="product-pic" v-if="refresh">
                     <img class="old-pic" v-if="oldPicShow" width="68" height="68" :src="oldPicUrl" alt="">
                     <el-upload
                           ref="upload"
                           :limit="1"
                           :show-file-list="true"
                           action="#"
                           :auto-upload="false"
                           list-type="picture-card"
                           :on-change="handleChange"
                           :on-preview="handlePictureCardPreview">
                        <i class="el-icon-plus"></i>
                     </el-upload>
                     <el-dialog :visible.sync="dialogVisible">
                        <img width="100%" :src="dialogImageUrl" alt="">
                     </el-dialog>
                  </el-col>
               </el-row>
               <el-row class="m-bottom-15">
                  <el-col :span="8" class="t-right l-height-26"><b class="needful">*</b><span v-text="$t('msg.product_name_a')">产品名称：</span></el-col>
                  <el-col :span="16">
                     <el-input  class="width-280" v-model="id" :disabled="action === 'edit'" :placeholder="$t('msg.product_name')" clearable></el-input>
                  </el-col>
               </el-row>
               <el-row>
                  <el-col :span="8" class="t-right l-height-26"><span v-text="$t('msg.topic_prefix_a')">Topic前缀：</span></el-col>
                  <el-col :span="16" style="margin: 3px 0;">
                     <span>{{topic.prefix + topic.value}}</span>
                  </el-col>
               </el-row>
               <el-row class="m-bottom-15">
                  <el-col :span="8" class="t-right l-height-26"><span style="visibility: hidden;" v-text="'aaa'"></span></el-col>
                  <el-col :span="16">
                     <el-input  class="width-280" v-model="topic.value" :placeholder="$t('msg.topic_info')" clearable></el-input>
                  </el-col>
               </el-row>
               <el-row class="m-bottom-15">
                  <el-col :span="8" class="t-right l-height-26"><b class="needful">*</b><span v-text="$t('msg.product_format_a')">产品规格：</span></el-col>
                  <el-col :span="16">
                     <el-input  class="width-280" v-model="standard" :placeholder="$t('msg.product_format')" clearable></el-input>
                  </el-col>
               </el-row>
               <el-row class="m-bottom-15">
                  <el-col :span="8" class="t-right l-height-26"><b class="needful">*</b><span v-text="$t('msg.product_number_a')">产品型号：</span></el-col>
                  <el-col :span="16">
                     <el-input  class="width-280" v-model="versions" :placeholder="$t('msg.product_number')" clearable></el-input>
                  </el-col>
               </el-row>
               <el-row class="m-bottom-15">
                  <el-col :span="8" class="t-right l-height-26"><b class="needful">*</b><span v-text="$t('msg.node_type_a')">节点类型：</span></el-col>
                  <el-col :span="16">
                     <el-select class="width-280" v-model="nodeType" :placeholder="$t('msg.select')" >
                        <el-option v-for="item in nodeList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                     </el-select>
                  </el-col>
               </el-row>
               <el-row class="m-bottom-15">
                  <el-col :span="8" class="t-right l-height-26"><span v-text="$t('msg.product_description_a')">型号描述：</span></el-col>
                  <el-col :span="16">
                     <el-input  class="width-280" v-model="versionsName" :placeholder="$t('msg.product_description')" clearable></el-input>
                  </el-col>
               </el-row>
               <el-row class="m-bottom-15">
                  <el-col :span="8" class="t-right l-height-26"><span v-text="$t('msg.remarks_a')">备注信息：</span></el-col>
                  <el-col :span="16">
                     <el-input class="width-280 type-textarea" type="textarea" v-model="remark" :placeholder="$t('msg.inp_remark_info')"></el-input>
                  </el-col>
               </el-row>
               <span slot="footer" class="dialog-footer">
            <el-button  @click="resetForm()" v-text="$t('msg.reset')">重置</el-button>
            <el-button class="bg-gradient" type="primary" @click="handleSave()" v-text="$t('msg.save')">保存</el-button>
         </span>
            </el-dialog>
         </el-form>
         <!-- 产品详情 -->
         <el-dialog :title="$t('msg.product_detail')" :visible.sync="detailsIsShow" :before-close="handleDetailsClose" width="780px">
            <el-divider content-position="left"><span v-text="$t('msg.detail_info')">详情信息</span></el-divider>
            <el-row class="m-bottom-15">
               <el-col :span="5" class="t-right"><span v-text="$t('msg.product_image_a')">产品图片：</span></el-col>
               <el-col :span="7">
                  <img :src="oldPicUrl" width="80" height="80" class="v-align-middle"/>
               </el-col>
            </el-row>
            <el-row class="m-bottom-15">
               <el-col :span="5" class="t-right"><span v-text="$t('msg.product_name_a')">产品名称：  </span></el-col>
               <el-col :span="7" v-text="id"></el-col>
               <el-col :span="5" class="t-right"><span v-text="$t('msg.category_a')">所属分类：</span></el-col>
               <el-col :span="7" v-text="productCategoryName"></el-col>
            </el-row>
            <el-row class="m-bottom-15">
               <el-col :span="5" class="t-right"><span v-text="$t('msg.product_key_a')">产品Key：</span></el-col>
               <el-col :span="7" v-text="productKey"></el-col>
               <el-col :span="5" class="t-right"><span v-text="$t('msg.product_secret_a')">产品秘钥：</span></el-col>
               <el-col :span="7" v-text="productSecret"></el-col>
            </el-row>
            <el-row class="m-bottom-15">
               <el-col :span="5" class="t-right"><span v-text="$t('msg.product_format_a')">产品规格：</span></el-col>
               <el-col :span="7" v-text="standard"></el-col>
               <el-col :span="5" class="t-right"><span v-text="$t('msg.product_number_a')">产品型号：</span></el-col>
               <el-col :span="7" v-text="versions"></el-col>
            </el-row>
            <el-row class="m-bottom-15">
               <el-col :span="5" class="t-right"><span v-text="$t('msg.product_description_a')">型号描述：</span></el-col>
               <el-col :span="7" v-text="versionsName"></el-col>
               <el-col :span="5" class="t-right"><span v-text="$t('msg.add_time_a')">添加时间：</span></el-col>
               <el-col :span="7" v-text="createTime"></el-col>
            </el-row>
            <el-row class="m-bottom-15">
               <el-col :span="5" class="t-right"><span v-text="$t('msg.remarks_a')">备注信息：</span></el-col>
               <el-col :span="7" v-text="remark"></el-col>
            </el-row>
            <span slot="footer" class="dialog-footer">
         <el-button  type="primary" @click="detailsIsShow = false" v-text="$t('msg.close')">关闭</el-button>
         </span>
         </el-dialog>
         <!-- 分页 -->
         <pagination :total="total" :page-size="limit" @handleSizeChangeSub="changePages" @handleCurrentChangeSub="changeCurrPage"/>
      </el-row>
   </div>
</template>
<script>
   import { urlObj } from '@/api/interface'
   import common from '@/common/js/common'
   export default {
      data() {
         return {
            sideData: [],             // 侧边栏数据
            tableData: [],            // 表格数据
            productSelect: [],        // 添加框 产品分类选择列表
            nodeList: [
               {
                  value: 'DIRECT',
                  label: '直连设备'
               },
               {
                  value: 'GATEWAYCHILD',
                  label: '网关子设备'
               },
               {
                  value: 'GATEWAY',
                  label: '网关设备'
               }
            ],           // 分类节点列表
            sideLoad: true,           // 侧边栏加载动画
            loading: true,            // 列表加载动画
            pid: '',                  // 侧边栏唯一标识 用于切换选中状态
            total: 0,                 // 列表总条数
            isShow: false,            // 是否显示添加/编辑弹窗
            detailsIsShow: false,     // 是否显示详情弹窗
            action: '',               // 当前操作行为
            productCategoryName: '',  // 产品分类名称
            boxIcon: true,            // 侧边栏是否展开
            /* 上传图片 */
            oldPicUrl: '',            // 旧产品图片地址
            oldPicShow: false,        // 旧图显示/隐藏
            dialogImageUrl: '',       // 放大图显示地址
            dialogVisible: false,     // 放大图默认显示/隐藏
            refresh: true,            // 刷新组件
            /* 列表过滤字段 */
            limit: '',                // 每页显示数
            page: 1,                  // 当前页 默认第一页
            search: '',               // 产品名称/编码
            // productTypeId: '',     // 产品分类ID
            /* 添加/编辑产品字段 */
            id: '',                   // 当前产品ID/名称
            topic: {
               prefix: '',
               value: '',
            },
            file: null,               // 产品图片资源
            productTypeId: '',        // 产品分类ID
            standard: '',             // 产品规格
            versions: '',             // 产品型号
            nodeType: '',             // 节点类型
            versionsName: '',         // 型号描述
            remark: '',               // 备注信息
            /* 查看详情 */
            createTime: '',           // 创建时间
            productKey: '',           // 产品key
            productSecret: '',        // 产品秘钥
            /* 弹窗提示文本 */
            confirm: '确定',
            cancel: '取消',
            prompt: '提示！',
            add_success: '添加成功！',
            update_success: '修改成功！',
            confirm_remove: '确定移除？',
            remove_success: '移除成功！',
            product_name_no_null: '产品名称不能为空！',
            product_name_zh_null: '产品名称不能含有中文！',
            product_format_no_null: '产品规格不能为空！',
            product_number_no_null: '产品型号不能为空！',
            product_description_no_null: '型号描述不能为空！',
            product_key_no_null: '产品Key不能为空！',
            node_type_no_null: '节点类型不能为空！',
         }
      },
      mounted() {
         this.limit = sessionStorage.getItem('pageSize') * 1
         this.getProductCategories()
         this.getProductList()
      },
      methods: {
         // 获取产品分类列表
         getProductCategories(){
            let url = urlObj.categoryList
            this.$axios.post(url, {}).then((res) => {
               if (res.success) {
                  this.sideLoad = false
                  let data = res.records
                  // 添加框 选择列表
                  this.productSelect = data.map(item => {
                     return {value: item.id, label: item.name}
                  })
                  this.sideData = data.map(item => {
                     item.icon = item.icon && window.getResourcesHost() + item.icon
                     return {id: item.id,name: item.name, icon: item.icon}
                  })
               }
            }).catch(error => {
               this.$message.error(error)
            })
         },
         // 获取产品列表
         getProductList(){
            let url = urlObj.getProductPages + `?limit=${this.limit}&page=${this.page}`
            let param = {
               productTypeId: this.productTypeId,
               search: this.search
            }
            this.$axios.post(url, param, 'json').then((res) => {
               if (res.success) {
                  this.loading = false
                  this.total = res.total
                  let tableData = res.records
                  tableData.forEach(item => item.image = item.image && window.getResourcesHost() + item.image)
                  this.tableData = tableData
               }
            }).catch(error => {
               this.$message.error(error)
            })
         },
         // 搜索产品
         handleQuery(bool){
            this.page = 1
            if (bool) return this.getProductList()
            this.search = ''
         },
         // 侧边列表当前选中
         selectSideList(val){
            this.page = 1 // 重置页码
            if (val.id !== this.pid){
               this.productTypeId = val.id
               this.productCategoryName = val.name
               this.getProductList()
               // 保存选中行id
               this.pid = val.id

               return
            }
            // 取消选中 清空属性
            this.pid = ''
            this.productTypeId = ''
            this.productCategoryName = ''
            this.$refs.productTable.setCurrentRow();
            this.getProductList()
         },
         // 改变显示条数
         changePages(num){
            this.limit = num
            this.getProductList()
         },
         // 改变当前页
         changeCurrPage(num){
            this.page = num
            this.getProductList()
         },
         // 获取上传文件资源
         handleChange(file) {
            // 验证文件格式、大小
            const imgFormat = file.raw.type === 'image/jpeg' || file.raw.type === 'image/png' || file.raw.type === 'image/gif'
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!imgFormat) {
               this.$message.error('上传产品图片只能是JPG/PNG/GIF格式!');
               // 强制刷新局部DOM节点
               this.refresh = false
               this.$nextTick(() => {
                  this.refresh = true
               })
               return
            }
            if (!isLt2M) {
               this.$message.error('上传产品图片大小不能超过 2MB!');
               this.refresh = false
               this.$nextTick(() => {
                  this.refresh = true
               })
               return
            }
            this.oldPicShow = false
            this.file = file.raw
         },
         // 点击图片查看大图
         handlePictureCardPreview(file){
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
         },
         // 新建产品
         handleAdd(){
            this.isShow = true
            this.action = '添加'
         },
         // 编辑产品
         handleEdit(row){
            if (row.image){
               this.oldPicShow = true
               this.oldPicUrl = row.image
            }
            this.productTypeId = row.productTypeId
            this.productCategoryName = row.productCategoryName
            this.id = row.id
            if (row.topicPrefix){
               let index = this.splitTopic(row.topicPrefix, '/', 2)
               this.topic.prefix = row.topicPrefix.substr(0, index + 1)
               this.topic.value = row.topicPrefix.substr(index + 1)
            }
            this.standard = row.standard
            this.versions = row.versions
            this.versionsName = row.versionsName
            this.nodeType = row.nodeType
            this.remark = row.remark
            this.isShow = true
            this.action = 'edit'
         },
         // 拆分Topic前缀值
         splitTopic(str, key, num){
            let index = str.indexOf(key)
            for (let i = 0; i < num; i ++){
               index = str.indexOf(key, index + 1)
            }
            return index
         },
         // 重置表单
         resetForm(){
            // 重置属性值
            this.id = ''
            this.productTypeId = ''
            this.productCategoryName = ''
            this.standard = ''
            this.versions = ''
            this.versionsName = ''
            this.nodeType = ''
            this.remark = ''
            this.topic.value = ''
            //this.topic.prefix = ''
            this.oldPicShow = false
            this.file = null
            // 强制刷新上传图片组件
            this.refresh = false
            this.$nextTick(() => {
               this.refresh = true
            })
         },
         // 保存产品
         handleSave(){
            // 产品名称
            if (!this.id) {
               this.$alert(this.product_name_no_null, '', {
                  confirmButtonText: this.confirm,
                  type: 'error'
               })
               return
            }
            let regEmail = /^[a-zA-Z0-9_]{0,}$/
            if (!regEmail.test(this.id)) {
               this.$alert(this.product_name_zh_null, '', {
                  confirmButtonText: this.confirm,
                  type: 'error'
               })
               return
            }
            // 产品规格
            if (!this.standard) {
               this.$alert(this.product_format_no_null, '', {
                  confirmButtonText: this.confirm,
                  type: 'error'
               })
               return
            }
            // 产品型号
            if (!this.versions) {
               this.$alert(this.product_number_no_null, '', {
                  confirmButtonText: this.confirm,
                  type: 'error'
               })
               return
            }
            // 节点类型
            if (!this.nodeType) {
               this.$alert(this.node_type_no_null, '', {
                  confirmButtonText: this.confirm,
                  type: 'error'
               })
               return
            }
            let url = urlObj.addProduct
            let param = new FormData()
            if (this.file){
               param.append('file', this.file)                // 文件
               param.append('module', 'hoteldevice')    // 所在模块
               param.append('menu', 'product')          // 所属菜单
               param.append('func', 'image')            // 图片功能
               param.append('category', 'image')        // 图片分类
               param.append('isThumb', true)            // 是否开启缩略图
            }
            param.append('id', this.id)
            param.append('productTypeId', this.productTypeId)
            param.append('standard', this.standard)
            param.append('versions', this.versions)
            param.append('versionsName', this.versionsName)
            param.append('nodeType', this.nodeType)
            param.append('remark', this.remark)
            if (this.action === 'edit'){
               url = urlObj.editProduct
               param.append('topicPrefix', this.topic.prefix + this.topic.value)
            }
            this.$axios.post(url, param,'file').then(res => {
               if (res.success) {
                  this.$message({
                     showClose: true,
                     message: this.action === 'add' ? this.add_success : this.update_success,
                     type: 'success'
                  })
                  this.handleClose()
                  this.getProductList()
               }
            }).catch(error => {
               this.$message.error(error)
            })
         },
         // 关闭新增/编辑弹窗
         handleClose(){
            this.isShow = false
            this.resetForm()
         },
         // 删除产品
         handleDel(id){
            this.$confirm(this.confirm_remove, this.prompt, {
               confirmButtonText: this.confirm,
               cancelButtonText: this.cancel,
               type: 'warning'
            }).then(() => {
               let url = urlObj.deleteProduct
               let param = {
                  productId: id
               }
               this.$axios.get(url, param).then((res) => {
                  if (res.success) {
                     this.$message({
                        showClose: true,
                        message: this.remove_success,
                        type: 'success'
                     })
                     this.getProductList()
                  }
               }).catch(error => {
                  this.$message.error(error)
               })
            })
         },
         // 查看产品详情
         handleDetails(row){
            this.detailsIsShow = true
            this.oldPicUrl = row.image
            this.id = row.id
            this.productCategoryName = row.productCategoryName
            this.productKey = row.productKey
            this.productSecret = row.productSecret
            this.standard = row.standard
            this.versions = row.versions
            this.versionsName = row.versionsName
            this.createTime = row.createTime
            this.remark = row.remark
         },
         // 关闭详情弹窗
         handleDetailsClose(){
            this.detailsIsShow = false
            this.oldPicUrl = ''
            this.id = ''
            this.productCategoryName = ''
            this.productKey = ''
            this.standard = ''
            this.versions = ''
            this.versionsName = ''
            this.createTime = ''
            this.remark = ''
         }
      }
   }
</script>
<style lang="scss">
   .product-pic{
      display: flex;
   }
   .product-pic .old-pic{
      border-radius: 6px;
      margin-right: 8px;
      border: 1px #1ABC9C solid;
   }
   .product-pic .el-upload{
      width: 70px!important;
      height: 70px!important;
      line-height: 80px;
   }
   .product-pic .el-upload-list li{
      width: 70px!important;
      height: 70px!important;
   }
</style>
